import React from "react";
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputField from "./InputField";

const TaskDesignTable = ({stepTask, setStepTask, step2codes, setStep2codes, handleOpenModal}) =>{
 
  
  const handleSub2TaskAdd = () =>{
    setStep2codes(prevCodes => {
      // 마지막 코드를 숫자로 변환
      const lastCode = parseInt(prevCodes.length>0?prevCodes[prevCodes.length - 1]:"0200", 10);  
      // 다음 코드 생성 ("01", "02", ... "09", "10", ...)
      const nextCode = (lastCode + 1).toString().padStart(4, '0');
      // 새 배열에 이전 코드와 다음 코드를 추가
      return [...prevCodes, nextCode];
    });

  }

  const handleRemoveCode = (codeToRemove) => {
    setStepTask(prevStepTask => {
      const entries = Object.entries(prevStepTask);
  
      // key가 4자리가 아닌 항목을 별도로 저장
      const nonFourDigitKeyEntries = entries.filter(([key, _]) => key.length !== 4);
  
      // key가 4자리인 항목만 필터링하여 새로운 키 생성
      const filteredEntries = entries.filter(([key, _]) => key.length === 4 && key !== codeToRemove);
      const newEntries = filteredEntries.map(([_key, value], index) => {
          const newKey = `02${(index + 1).toString().padStart(2, '0')}`;
          return [newKey, value];
      });
  
      // newEntries와 nonFourDigitKeyEntries를 합침
      const combinedEntries = [...newEntries, ...nonFourDigitKeyEntries];
      
      return Object.fromEntries(combinedEntries);
    });
  
     // 값 제거
     const filteredStep2Code = step2codes.filter(value => value !== codeToRemove);

     // 새로운 값으로 업데이트
     const newStep2Code = filteredStep2Code.map((_, index) => `02${(index + 1).toString().padStart(2, '0')}`);
 
     setStep2codes(newStep2Code);

  };

 const step2RenderButtons = () => step2codes.map((code, i) => <tr>
      <td>
        <InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"구분"}/>
      </td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"규격W"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"규격H"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"규격D"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"수량"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"등분"}/></td>
      {/* <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"도장색상"}/></td> */}
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"납기일"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"납품장소"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"시작일"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"종료일"}/></td>
      <td><InputField stepTask={stepTask} setStepTask={setStepTask} code={code} field={"비고"}/></td>
      <td>
        <Form.Control
          type="text"
          value={stepTask[code]?.["worker"] ?? ''}
          onClick={() => handleOpenModal(code)}
          readOnly
        />
      </td>
      <td>
        <Button className={"ms-1 btn-danger btn-sm"} onClick={()=>handleRemoveCode(code)}>&times;</Button>
      </td>
    </tr>);
return (<>
      <table className="table table-data mt-3 table-bordered ">
        <thead>
          <tr>
            <th>구분</th>
            <th>규격W</th>
            <th>규격H</th>
            <th>규격D</th>
            <th>수량</th>
            <th>등분</th>
            {/* <th>도장색상</th> */}
            <th>납기일</th>
            <th>납품장소</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>비고</th>
            <th>설계자</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {step2RenderButtons()}
        </tbody>
      </table>
      <Button variant="primary" onClick={handleSub2TaskAdd} className="w-100 btn0-sm py-1" >작업 추가</Button>
      </>
  );
}



export default TaskDesignTable;