import * as recoil from 'recoil';

import Api from "../api/Api.js";
import {isLoadingState} from '../components/Loading';
import md5 from 'crypto-js/md5';
import Cookies from 'js-cookie';

// 카테고리 상태를 저장하는 atom 생성
export const userInfoState = recoil.atom({
  key: 'userState', // 고유한 키
  default: {},          // 기본값은 빈 객체
});



export function Signin() {
  const setLoading = recoil.useSetRecoilState(isLoadingState);
  const setUserInfo = recoil.useSetRecoilState(userInfoState);

  const _Signin = async (userID, password) => {
    try {
      setLoading(true);
      const response = await Api.signin(userID, md5(password).toString());
      setLoading(false);
      if (!response.data.ret) {
        alert(response.data.message);
        return false;
      }
      
      // 쿠키 설정
      Cookies.set('utk', response.data.userinfo["utk"], { expires: 7 }); // 7일 후에 만료

      setUserInfo(response.data.userinfo);
      return true;     
    } catch (error) {
      alert(error);
    }
  };

  return _Signin;
}



export const UserinfoGet = () => {
  const setUserInfo = recoil.useSetRecoilState(userInfoState);

  const _fetch = async () => {
    try {
      const response = await Api.userinfoGet();
    
      if (!response.data.ret) {
        //alert(response.data.message);
        return false;
      }
      setUserInfo(response.data.userinfo);
      return true;     
    } catch (error) {
      alert(error);
    }
    return false;
  };

  return _fetch;
}


export const SignOut = () => {
  const setUserInfo = recoil.useSetRecoilState(userInfoState);

  const _fetch = async () => {
    try {
      var response = await Api.signout();
      if (!response.data.ret) {
        alert(response.data.message);
        return false;
      }
      Cookies.remove("utk");
      setUserInfo({});
      return true;
    } catch (error) {
      alert(error);
    }
    return false;
  };

  return _fetch;
}

