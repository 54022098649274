import * as recoil from 'recoil';
import BounceLoader from "react-spinners/BounceLoader";
import styled from '@emotion/styled';


export const isLoadingState = recoil.atom({
  key : "isLoadingState",
  default : false
});

// import { useSetRecoilState } from "recoil";
// import { isLoadingState } from "../../components/Loading";
// const setLoading = useSetRecoilState(isLoadingState);

const FullPageDiv = styled.div`
  background-color: rgba(0,0,0,0.0);
  width: 100%;
  height: 100vh;
  z-index:100000;
  position: absolute;
`;

const CenteredDiv = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:100001;
`;

export const Loading = ()=>{
    const isLoading = recoil.useRecoilValue(isLoadingState);
    if (isLoading === false)return <></>;
    return (
      <FullPageDiv>
        <CenteredDiv>
          <BounceLoader
            color={"blue"}
            loading={true}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </CenteredDiv>
      </FullPageDiv>
    );
};
