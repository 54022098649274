import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from './DashboardHeader.jsx';
import { dataParser } from "./DataParser.js"
import Api from "../../api/Api.js";


const DataTable = ( {data2, expanded, handleYNClick} ) => {
  const [tableRows, setTableRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    var data = dataParser(expanded, data2);

    if (data.length > 0) {
      // 테이블 크기 계산
      const maxRow = Math.max(...data.map(item => item[0]));
      const maxCol = Math.max(...data.map(item => item[1]));
      
      // 빈 테이블 생성
      const rows = Array.from({ length: maxRow }, (_, rowIndex) => {
        return Array.from({ length: maxCol }, (_, colIndex) => {
          return { id: `cell-${rowIndex + 1}-${colIndex + 1}`, value: '' ,task_ID:"", step_code:"", began_at:null, completed_at:null};
        });
      });

      // 데이터 채우기
      data.forEach(([row, col, value, task_ID, step_code,  detail, key]) => {
        rows[row - 1][col - 1].value = value;
        rows[row - 1][col - 1].task_ID = task_ID;
        rows[row - 1][col - 1].step_code = step_code;
        rows[row - 1][col - 1].began_at = detail!==undefined?detail["began_at"]:null;
        rows[row - 1][col - 1].completed_at = detail!==undefined?detail["completed_at"]:null;
        rows[row - 1][col - 1].key = key?? "";
      });

      setTableRows(rows);
    }
  }, [data2, expanded]);


  const handleClick = async (colIndex,cell) =>{
    if (colIndex === 0) {
      if(window.confirm("수정창으로 이동할까요??"))
        navigate('/tasks/create?ID='+cell.value);
    }    
    if (cell.value === "Y" || cell.value === "N"  || cell.value === "P") {
      handleYNClick(cell)
    }
    if (cell.key === "비고") {
      var bg = window.prompt('비고란을 입력해주세요');
      if (bg != null){
        var ret2 = (await Api.taskBGPut(cell["task_ID"], cell["step_code"], bg)).data;
        if (!ret2["ret"]) {
          return alert(ret2["message"]);
        }
        document.getElementById(cell.id+"_"+cell.key).textContent = bg;
      }
    }
  };



  return (
    <table className='table table-striped table-sm table-bordered' id="table-to-xlsx" style={{width:"97%"}}>
      <DashboardHeader expanded={expanded} />
      <tbody>
        {tableRows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, colIndex) => (
              <td key={cell.id} id={cell.id} className={colIndex<(expanded?5:4)?'fw-bold':''} onClick={()=>handleClick(colIndex, cell)}>
                {(cell.value==="N")?
                  (<div className='text-danger fw-bold'>{cell.value}</div>)
                :
                  (<div id={cell.id+"_"+cell.key}>{cell.value}</div>)
                }
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};


export default DataTable;