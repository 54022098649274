
import { createBrowserRouter } from 'react-router-dom';
import App from '../App'
import menus from './Menus'
import Home from '../pages/dashboard/Home'

var parserMenu = ()=>{
    const grouped = {};

    const traverse = (menu, parentPath = '') => {
        if (menu.element) {  // Only consider items with 'element' property
            if (menu.path) {
                const fullpath = menu.path;
                const strippedPath = fullpath.replace(parentPath, '');
                const key = strippedPath.split('/')[1] || 'root';

                if (!grouped[key]) {
                    grouped[key] = {
                        path: key,
                        children: []
                    };
                }
                // Only store 'path' and 'element' properties
                grouped[key].children.push({ path: strippedPath, element: menu.element });
            }
        }

        if (menu.children) {
            menu.children.forEach(child => traverse(child, menu.path));
        }
    };

    menus.forEach(traverse);
    
    return Object.values(grouped); 
};
    

const routerBuilder =()=>{
    var grouped = parserMenu();
    grouped.unshift({path: '', element: <Home />});
    return createBrowserRouter([
    {      
      path: '/',element: <App />,
      children: grouped
    },
  ]);
}

const router = routerBuilder();

export default router;