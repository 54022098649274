import React, { Component, useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import styled from '@emotion/styled';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import md5 from 'crypto-js/md5';
import Api from "../../api/Api";

import { useSetRecoilState } from "recoil";
import { isLoadingState } from "../../components/Loading";
import { Tabs, Tab,InputGroup ,FormControl} from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import {membertypeTitle} from "../../utils.js"

interface UserModalProps {
  show: boolean;
  handleClose: () => void; // 예를 들면, 함수 타입이 이렇게 될 수 있습니다.
  item: {};
}


const UserModal: React.FC<UserModalProps> = ({ show, handleClose, item }) =>  {
  const setLoading = useSetRecoilState(isLoadingState);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(item["name"] || '');
  const [phone, setPhone] = useState(item["phone"] || '');
  const [grade, setGrade] = useState(item["직급"] || '');
  const [membertype, setSelectedValue] = useState(item["membertype"] || 0);
  
  const handleSelect = (e) => {
    setSelectedValue(e);
  };

  const dropdown = ()=>{
    return (<div><Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {membertypeTitle(membertype)}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1">권한없음</Dropdown.Item>
        <Dropdown.Item eventKey="2">작업자</Dropdown.Item>
        <Dropdown.Item eventKey="4">관리자</Dropdown.Item>
        <Dropdown.Item eventKey="8">마스터</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>);
  }



  const handleSave = async (event) => {
    setLoading(true);

    const response = await Api.userPut(item["uidx"], name, phone, membertype, grade);
    setLoading(false);
    if (!response.data.ret) {
      alert(response.data.message);
      return false;
    }
    alert("저장 하였습니다.");
  }

  const handleChangePassword = async (event) => {
    setLoading(true);

    const response = await Api.userPwdPut(item["uidx"], md5(password).toString());
    setLoading(false);
    if (!response.data.ret) {
      alert(response.data.message);
      return false;
    }
    alert("변경 하였습니다.");
  }

  

  return item==null?<></>:(
      <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
              <Modal.Title>[{item.uidx}] {item.user_id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Tabs defaultActiveKey="tab1">
            <Tab eventKey="tab1" title="기본정보변경">
              <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>이름</Form.Label>
                  <Form.Control 
                      placeholder="이름을 입력해주세요" 
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>전화번호</Form.Label>
                  <Form.Control 
                      placeholder="전화번호를 입력해주세요" 
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>직급</Form.Label>
                  <Form.Control 
                      placeholder="직급을 입력해주세요" 
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>권한등급</Form.Label>
                  {dropdown()}      
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button variant="primary" onClick={handleSave} >저장</Button>
                </div>
            </Tab>
            <Tab eventKey="tab3" title="비밀번호 변경">
              
              <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>Password</Form.Label>
                  <Form.Control 
                      placeholder="Password" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
      
                <div className="d-flex justify-content-end">
                  <Button variant="primary" onClick={handleChangePassword} >저장</Button>
                </div>
            </Tab>       
          </Tabs>
         
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
      </Modal>
  );
}




export default UserModal;
