import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';

const FinishButtonGroup = ({handleClick, finishOnly}) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <ButtonGroup size="sm">
        <Button variant="secondary"  onClick={()=>handleClick(-1)} style={{ fontWeight: finishOnly === -1 ? 'bold' : 'normal' }}>
          <FaSync />  전체 
        </Button>
        <Button variant="secondary" onClick={()=>handleClick(1)} style={{ fontWeight: finishOnly === 1 ? 'bold' : 'normal' }}>
          완료된 작업만
        </Button>
        <Button variant="secondary" onClick={()=>handleClick(0)} style={{ fontWeight: finishOnly === 0 ? 'bold' : 'normal' }}>
          완료안된 작업만
        </Button>
        
      </ButtonGroup>      
    </div>
  );
}

export default FinishButtonGroup