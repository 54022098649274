import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';

const TypeButtonGroup = ({handleClick, type}) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <ButtonGroup size="sm">
        <Button variant="secondary"  onClick={()=>handleClick('')} style={{ fontWeight: type === '' ? 'bold' : 'normal' }}>
          <FaSync />  전체 
        </Button>
        <Button variant="secondary" onClick={()=>handleClick('K')} style={{ fontWeight: type === 'K' ? 'bold' : 'normal' }}>K</Button>
        <Button variant="secondary" onClick={()=>handleClick('C')} style={{ fontWeight: type === 'C' ? 'bold' : 'normal' }}>C</Button>        
      </ButtonGroup>      
    </div>
  );
}

export default TypeButtonGroup