import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../api/Api.js";

import {membertypeTitle} from "../../utils.js"

interface Column {
  id: string;
  checkbox?: boolean;
  label: string;
  minWidth?: string | number;
  align?: "right"|"center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "uidx",
    label: "uidx",
    minWidth: "5rem",
    format: (value: number) => value.toString(),
  },

  { id: "name", label: "name", minWidth: "5rem" },
  { id: "phone", label: "phone", minWidth: 100 },
  { id: "membertype", label: "membertype", minWidth: 100 },
];

export const UsersTable = ({rows, setSelected}) => {
  
  if (rows == null)return <></>;

  return (
  
    <div className="table-container">
      <Table  className="mb-0 sticky-table" striped bordered hover size="lg" >
        <thead >
          <tr>
          {columns.map((column) => (
            <th
              key={column.id}          
              style={{textAlign:column.align, width: column.minWidth }}
            >
              {column.label}
            </th>
          ))}
          <th style={{ textAlign:"center", width: "5rem"}}>선택</th>
          </tr>
        </thead>
        <tbody>
        {rows.map((row) => {
            return (
              <tr tabIndex={-1} key={row.uidx}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <td key={column.id} align={column.align}>
                      {
                      column.id === "membertype"?
                        membertypeTitle(value):
                      column.checkbox === true ? (
                        <Form.Check aria-label="option 1" 
                        checked={value === 1}
                        disabled/>
                      
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : (
                        value
                      )}
                    </td>
                  );
                })}
                <td style={{ textAlign:"center"}}>
                  <Button variant="primary" size="sm" onClick={()=>setSelected(row)}>
                    선택
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      
    </div>
  );
}

export default UsersTable;