import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../data/UserInfo.jsx';
import { FaExpand, FaCompress } from 'react-icons/fa';

const PeriodButtonGroup = ({handleClick, periodMode, handleExpand, expanded}) => {
  const userInfo = useRecoilValue(userInfoState); 
  return (
    <div style={{ textAlign: 'left' }}>
      <ButtonGroup size="sm">
        <Button variant="secondary"  onClick={()=>handleClick(0)} style={{ fontWeight: periodMode === 0 ? 'bold' : 'normal' }}>
          <FaSync />  전체 
        </Button>
        <Button variant="secondary" onClick={()=>handleClick(1)} style={{ fontWeight: periodMode === 1 ? 'bold' : 'normal' }}>오늘</Button>
        <Button variant="secondary" onClick={()=>handleClick(2)} style={{ fontWeight: periodMode === 2 ? 'bold' : 'normal' }}>이번주</Button>
        <Button variant="secondary" onClick={()=>handleClick(3)} style={{ fontWeight: periodMode === 3 ? 'bold' : 'normal' }}>이번달</Button>
        <Button variant="secondary" onClick={()=>handleClick(4)} style={{ fontWeight: periodMode === 4 ? 'bold' : 'normal' }}>올해</Button>
        {(userInfo["membertype"] > 1) ?<Button variant="secondary" onClick={handleExpand}>{expanded?<FaCompress />:<FaExpand />}</Button> :null}
      </ButtonGroup>      
    </div>
  );
}

export default PeriodButtonGroup