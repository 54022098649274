import React, { useEffect, useState } from "react";
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";
import Api from "../../api/Api.js";
import Table from 'react-bootstrap/Table';

import { useSetRecoilState } from "recoil";
import { isLoadingState } from "../../components/Loading.jsx";


const Row = ({keyProp, value, menuName, canDelete=false, onReload})=>{
  const [inputValues, setInputValues] = useState({key: keyProp, value:value});

  const handleInputChange = (e, column) => {
      setInputValues({
          ...inputValues,
          [column]: e.target.value
      });
  };

  const handleButtonClick = async () => {
    if (menuName === "추가") {
      if (await onPut() === true) {
        setInputValues({key:"", value:""});
      }
    } else if (menuName === "수정") {
      onPut();
    }
  }

  const handleDelClick = () => {
    if (!window.confirm("정말로 삭제하시겠습니까?"))
      return;

    Api.settingValuesDel(inputValues.key).then((response) => {
      if (!response.data.ret) {
        return alert(response.data.message);
      }      
      onReload();
    }).catch((error) => {
        console.error("Error fetching data:", error);
    });
  }


  const onPut = async () => {
    if (inputValues.maxLen > 65535)
      inputValues.maxLen = 65535;
    var response = await Api.settingValuesPut(inputValues.key, inputValues.value);
    if (!response.data.ret) {
      alert(response.data.message);
      return false;
    }
    onReload();      
    return true;
  }

  return (
    <tr>      
      <td>
          <input 
            className="form-control"
              type="text" 
              value={inputValues.key} 
              onChange={e => handleInputChange(e, 'key')}
          />
      </td>
      <td>
          <input 
              className="form-control"
              type="text" 
              value={inputValues.value} 
              onChange={e => handleInputChange(e, 'value')}
          />
      </td>   
      <td>
            <button className="btn btn-primary btn-sm" onClick={handleButtonClick}>{menuName}</button>
            {canDelete===true && <button className="ms-1 btn btn-danger btn-sm" onClick={handleDelClick}>삭제</button>}
        </td>

    </tr>
  );
}


const ProductItems = () => {
  
  const setLoading = useSetRecoilState(isLoadingState);
  const [items, setItems] = useState([]);

  React.useEffect(() => {onReload();}, []);

  const onReload = () => {
    setLoading(true);
    
    Api.settingValuesScan().then((response) => {

      setItems(response.data.setting);
      
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error("Error fetching data:", error);
    });    
  };


  return (<div className="table-container">
    <Table className="sticky-table" striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{textAlign:"center", width: "10rem"}}>key</th>
          <th style={{textAlign:"center", }}>value</th>
          <th style={{textAlign:"center", width: "7rem"}}>메뉴</th>
        </tr>
      </thead>
      <thead>
        <Row menuName={'추가'} canDelete={false} onReload={onReload}/>
      </thead>
      <tbody>
        {items && Object.entries(items).map(([key, value], index) => (
            <Row key={key} keyProp={key} value={value} menuName={'수정'} canDelete={true} onReload={onReload}/>
          )) 
        }
      </tbody>
    </Table>    
  </div>);
};

export const ValueSetting = () => {

  return (
    <Page>
      <PageHeader>세팅값 설정</PageHeader>
      <PageMain>
          <ProductItems/>
      </PageMain>
    </Page>
  );
}

  

export default ValueSetting;
