import styled from '@emotion/styled';
import React from 'react';
// @ts-ignore
import { Typography } from '../components/Typography.tsx'
import { Link, useLocation} from 'react-router-dom';
import Hamburger from 'hamburger-react'

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;


export const SidebarHeader = ({state, ...rest }) => {
    const {collapsed, setCollapsed} = state;

  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Hamburger toggled={collapsed} toggle={setCollapsed}/>
        <div  style={{ opacity: collapsed ? 0 : 1 }}>
            <Link to="/" style={{ textDecoration: 'none' }} >
              <img src='/spm_logo.png' style={{width:"6rem"}}/>
            </Link>
        </div>
      </div>
    </StyledSidebarHeader>
  );
};