import * as recoil from 'recoil';

import Api from "../api/Api.js";



// 카테고리 상태를 저장하는 atom 생성
export const masterProductsState = recoil.atom({
  key: 'masterProductsState', // 고유한 키
  default: [],          // 기본값은 빈 객체
});

export function SettingsScan() {
  
  const setMasterProducts = recoil.useSetRecoilState(masterProductsState);
  
  const fetchAndSetSettings = async () => {
    try {
      const response = await Api.settingScan();
      const data = await response.data;
      if (data.ret) {
        setMasterProducts(data.setting.masterProducts); // setting the Recoil state        
      }
    } catch (error) {
      // Handle or throw the error appropriately
      console.error(error);
    }
  };

  return fetchAndSetSettings;
}


