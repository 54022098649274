import { formatTimestamp } from '../../utils.js';

/**
 * 표의 데이타를 채워서 돌려줌
 */
export function dataParser(expand, jsonData) {
  let result = [];
  let currentRow = 1;
 
  jsonData.forEach(project => {
    
    const design = project["details"].filter(detail => detail["step_code"] != null && detail["step_code"].length === 4);
    const manufacture = project["details"].filter(detail =>  detail["step_code"] != null &&  detail["step_code"].length !== 4);

    // 설계 파트에서 수량합산 
    let totalCount = countDesignTask(design);

    // 프로젝트 기본 정보 추가
    const nextColIndex = insertInformation(expand, currentRow, result, project, totalCount);
  
    // 설계 파트 데이타 추가 
    let maxDetailCols = insertDesignTask(expand, nextColIndex, currentRow, result, design);

    // 제조 공정 데이타 추가
    insertManufacture(expand, maxDetailCols, currentRow, result, manufacture);

    //다음 프로젝트는 몇번째 row에서 시작하는지 결정
    currentRow += Math.max(design.length, 1);

  });
  return result ;
}

// 프로젝트 기본 정보 추가
function insertInformation(expanded, currentRow, result, project, totalCount) {
  var date = formatTimestamp(project["created_at"]);
  date = date.substring(0,10);
  var col = 1;
  result.push([currentRow, col++, project["ID"],"","",project,"ID"]);
  if (expanded)
    result.push([currentRow, col++, date]);
  result.push([currentRow, col++, project["수주번호"],"","",project,"수주번호"]);
  
  result.push([currentRow, col++, project["거래처"],"","",project,"거래처"]);  

  result.push([currentRow, col++, project["프로젝트명"],"","",project,"프로젝트명"]);
  
  if (expanded)
    result.push([currentRow, col++, totalCount?.toLocaleString() ??"","","",project,"총수량"]);
  
  if (expanded)result.push([currentRow, col++, project["예상매출액"]?.toLocaleString() ??"","","",project,"예상매출액"]);

  if (expanded)result.push([currentRow, col++, project["도장색상"] ??"","","",project,"도장색상"]);
  if (expanded)result.push([currentRow, col++, project["도장두께"] ??"","","",project,"도장두께"]);
  if (expanded)result.push([currentRow, col++, project["도장내진"] ??"","","",project,"도장내진"]);
  if (expanded)result.push([currentRow, col++, project["도장기타"] ??"","","",project,"도장기타"]);
  
  return col;
}


function countDesignTask(designs) {
  return designs.reduce((totalQuantity, detail) => {
    return totalQuantity + Number(detail["수량"] || 0);
  }, 0);
}


//설계쪽 자료 처리
function insertDesignTask(expand, nextColIndex, currentRow, result, designs) {
  let maxDetailCols = 0;
  var keys = !expand?["구분",  "수량", "규격W", "규격H", "규격D", "납기일", "completed"] : ["구분", "수량", "등분", "규격W", "규격H", "규격D"  ,"납기일","납품장소","시작일", "종료일","name","began_at","completed_at", "비고"];

  designs.forEach((detail, index) => {
    const detailRow = currentRow + index;
    let colIndex = nextColIndex;
    keys.forEach((k)=>{
      var v = detail[k] ?? "";
      if (k === "completed") {
        if (detail[k] === 1) {
          v = "Y";
        } else {
          if (detail["began_at"] == null)
            v = "N";
          else
            v = "P";
        }
      }

      if (k === "began_at") {
        if (detail[k] === undefined || detail[k] === null )
          v = "" 
        else
          v = formatTimestamp(detail[k]);
      }

      if (k === "completed_at")  {
        if (detail[k] === undefined || detail[k] === null )
          v = ""; 
        else
          v = formatTimestamp(detail[k]);
      }

      result.push([detailRow, colIndex++, v, detail["task_ID"], detail["step_code"], detail, k]);
    });
    maxDetailCols = Math.max(maxDetailCols, colIndex);
  });
  //return값, td를 몇개나 차지하나?
  return expand?(12+keys.length):12;
}

function insertManufacture(expand, maxDetailCols, currentRow, result, manufacture) {
  let startColIndexForDetail2 =  maxDetailCols;
  let colIndex = startColIndexForDetail2;

  var keys = expand?["시작일", "종료일", "name", "began_at", "completed_at", "비고"]:["completed"];

  ["pg", "제조1", "제조2", "제조3", "출하", "qc", "as"].forEach(code=>{
    var detail = manufacture.filter((e)=>e["step_code"] === code)[0];
    if (detail === undefined ){
      keys.forEach((k)=>{
        result.push([currentRow, colIndex++, "", "", ""]);
      });
  
    }else {
      keys.forEach((k)=>{
        var v = detail[k] ?? "";
        if (k === "completed") {
          if (detail[k] === 1) {
            v = "Y";
          } else {
            if (detail["began_at"] == null)
              v = "N";
            else
              v = "P";
          }
        }
        if (k === "began_at") {
          if (detail[k] === undefined || detail[k] === null )
            v = "" 
          else
            v = formatTimestamp(detail[k]);
        }

        if (k === "completed_at")  {
          if (detail[k] === undefined || detail[k] === null )
            v = ""; 
          else
            v = formatTimestamp(detail[k]);
        }
        result.push([currentRow, colIndex++, v, detail["task_ID"], detail["step_code"], detail, k]);
      });
  
    }
  });
}


  

