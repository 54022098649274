import React, { useState, useEffect } from 'react';

const DashboardHeader = ({expanded}) =>{
  
  const defautTask = (c, size) =>{
    return (<>
        <th colSpan={c} className={"small"} style={{width:size}}>설계</th>
        <th colSpan={c} className={"small"} style={{width:size}}>P/G</th>
        <th colSpan={c} className={"small"} style={{width:size}}>제조1</th>
        <th colSpan={c} className={"small"} style={{width:size}}>제조2</th>
        <th colSpan={c} className={"small"} style={{width:size}}>제조3</th>
        <th colSpan={c} className={"small"} style={{width:size}}>출하</th>
        <th colSpan={c} className={"small"} style={{width:size}}>QC </th> 
        <th colSpan={c} className={"small"} style={{width:size}}>AS </th>  
    </>)
  }


  const theadCollapsed = () =>{
    return (<thead className='table-primary' style={{position:'sticky', top:0, zIndex:10}}>
      <tr>
        <th colSpan={4}>고객정보</th>
        <th colSpan={2}>상품정보</th>
        <th colSpan={3}>규격정보</th>
        <th colSpan={1}>납기정보</th>
        <th colSpan={6}>제조공정사항</th>
        <th colSpan={2}  style={{width:"7rem"}}>제조관리사항</th>
      </tr>
      <tr>
        <th style={{width:"2rem"}}>ID</th>
        <th style={{width:"12rem"}}>수주번호</th>
        <th style={{width:"10rem"}}>거래처</th>
        <th style={{width:"10rem"}}>프로젝트명</th>
        <th style={{width:"8rem"}}>구분</th>
        <th style={{width:"6rem"}}>수량</th>
        <th style={{width:"4rem"}}>W</th>
        <th style={{width:"4rem"}}>H</th>
        <th style={{width:"4rem"}}>D</th>
        <th style={{width:"6rem"}}>납기일</th>
        { defautTask(1, "3rem") }
      </tr>

    </thead>          
    );
  }



  const theadExpanded = () =>{
    return (<thead className='table-primary' style={{position:'sticky', top:0, zIndex:10, backgroundColor:'white'}}>
      <tr>
        <th colSpan={6}>고객정보</th>
        <th>매출정보</th>
        <th colSpan={4}>도장정보</th>
        <th colSpan={3}>상품정보</th>
        <th colSpan={3}>규격정보</th>
        <th colSpan={2}>납기정보</th>
        <th colSpan={36}>제조공정사항</th>
        <th colSpan={14}>제조관리사항</th>        
      </tr>
      <tr>
        <th rowSpan={2} style={{width:"2rem"}}>ID</th>
        <th rowSpan={2} style={{width:"8rem"}}>접수일</th>
        <th rowSpan={2}  style={{width:"14rem"}}>수주번호</th>
        <th rowSpan={2}  style={{width:"10rem"}}>거래처</th>
        <th rowSpan={2}  style={{width:"10rem"}}>프로젝트명</th>
        <th rowSpan={2}  style={{width:"5rem"}}>총수량</th>
        <th rowSpan={2}  style={{width:"6rem"}}>예상매출액</th>
        <th rowSpan={2}  style={{width:"6rem"}}>도장색상</th>
        <th rowSpan={2}  style={{width:"6rem"}}>도장두께</th>
        <th rowSpan={2}  style={{width:"6rem"}}>내진</th>
        <th rowSpan={2}  style={{width:"6rem"}}>기타</th>

        <th rowSpan={2}  style={{width:"8rem"}}>구분</th>
        <th rowSpan={2}  style={{width:"6rem"}}>수량</th>
        <th rowSpan={2}  style={{width:"6rem"}}>등분</th>
        {/* <th rowSpan={2}  style={{width:"6rem"}}>도장색상</th> */}
        <th rowSpan={2}  style={{width:"4rem"}}>W</th>
        <th rowSpan={2}  style={{width:"4rem"}}>H</th>
        <th rowSpan={2}  style={{width:"4rem"}}>D</th>
        <th rowSpan={2}  style={{width:"6rem"}}>납기일</th>
        <th rowSpan={2}  style={{width:"6rem"}}>납품장소</th>
        

        {defautTask(6, "6rem")}
      </tr>
      <tr>        
        {Array.from({ length: 8 }).map((_, index) => (
          <>
            <th key={index*6+0} className="small" style={{ width: "6rem" }}>시작일</th>
            <th key={index*6+1} className="small" style={{ width: "6rem" }}>종료일</th>
            <th key={index*6+2} className="small" style={{ width: "6rem" }}>담당자</th>
            <th key={index*6+3} className="small" style={{ width: "15rem" }}>작업시작</th>
            <th key={index*6+4} className="small" style={{ width: "15rem" }}>작업종료</th>
            <th key={index*6+5} className="small" style={{ width: "10rem" }}>비고</th>
          </>
        ))}
      </tr>
    </thead>          
    );
  }

  
  return (
    expanded === true? theadExpanded() : theadCollapsed ()
  );

}


export default DashboardHeader;

