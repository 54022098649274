import React, { Component, useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';


export const TaskNumberInput = ({taskNumber,setTaskNumber })=> {
  const handleInputChange = (e) => {
    let value = e.target.value.toUpperCase().slice(0, 16);

    // 중복된 하이픈을 제거하고, 필요한 경우 하이픈 추가
    value = value.replace(/-+/g, '-').replace(/^([CK])(?!\-)/, '$1-');
    
    // 정규 표현식을 사용하여 입력값의 형식을 검사 및 조정
    const pattern = /^(C|K)-?(\d{0,4})-?(\d{0,2})-?([A-Z]{0,3})-?(\d{0,2})$/;
    let match = value.match(pattern);
  
    if (match) {
      value = `${match[1] || ''}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}${match[4] ? '-' + match[4] : ''}${match[5] ? '-' + match[5] : ''}`;
    }
  
    setTaskNumber(value);
  }

  return (
    <div>
      <Form.Control 
        value={taskNumber} 
        placeholder="수주번호 입력" 
        onChange={handleInputChange}
      />
      <span className="small mt-2 text-secondary">예시: C-2311-01-SPM-00, K-2311-02-SPM-00</span> 
    </div>
    );  
};
export default TaskNumberInput;