import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import UserSearch from "../users/UserSearch.tsx";
import UsersTable from "./UsersTable.tsx";
import { UserItem } from "../../types/UserItem.ts"
import Api from "../../api/Api";

const UserFinderModal = ({show, onHide, onSave}) => {
  const [searchedData, setSearchedData] = React.useState<UserItem[]>([]);
  const [searchTerm, setSearchTerm] = React.useState(""); // _searchTerm를 상태로 관리


  const handleSubmit = (row) => {
    onSave(row);
  };

  const handleSearch = (_searchTerm) => {    
    // 앞뒤 공백을 제거
    const trimmedTerm = _searchTerm.trim();
    if (!trimmedTerm) {
      setSearchedData([]);
      return;
    }
    setSearchTerm(trimmedTerm);
    Api.usersSearchScan(trimmedTerm)
      .then((response) => {
        if (!response.data.ret) {
          return alert(response.data.message);
        }
        setSearchedData(response.data.items);
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>작업자 검색</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <UserSearch onSearch={handleSearch}/>
      {searchedData.length > 0 && <UsersTable rows={searchedData} setSelected={handleSubmit} />}

      </Modal.Body>
    </Modal>
  );
}

export default UserFinderModal;
