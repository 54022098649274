import { Sidebar, Menu, MenuItem, SubMenu, MenuItemStyles, menuClasses } from 'react-pro-sidebar';
import { Link, useLocation} from 'react-router-dom';
// @ts-ignore
import { SidebarHeader } from './SidebarHeader.tsx';
// @ts-ignore
import { Typography } from '../components/Typography.tsx';

// @ts-ignore
import { Badge } from '../components/Badge.tsx';

import { Button } from 'react-bootstrap';
import React, {useEffect} from 'react';

import { removeLastNodesWithoutElement, removeLastNodesWithoutElement2 } from './SideMenuFilter.js';

import { useRecoilValue } from 'recoil';
import { userInfoState } from '../data/UserInfo.jsx';


type Theme = 'dark';

const themes = {
  dark: {
    sidebar: {
      backgroundColor: '#0b2948',
      color: '#8ba1b7',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#00458b',
        color: '#b6c8d9',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


// 사이드바 컴포넌트
const SideMenuBuilder = ({signoutHandler}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [theme, setTheme] = React.useState<Theme>('dark');

  const menus = removeLastNodesWithoutElement();
  const menus2 = removeLastNodesWithoutElement2();
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


    // URL의 path값을 받아올 수 있다.
    const pathName = useLocation().pathname;
    const userInfo = useRecoilValue(userInfoState); 
    
    return (
      <div style={{ display: 'flex', height: '100vh', position:"relative"}}>
        <Sidebar
          onBackdropClick={() => setCollapsed(false)}
          
          collapsed={collapsed}
          toggled={toggled}
          backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
          rootStyles={{
            color: themes[theme].sidebar.color,
          }}
        >
          <SidebarHeader state={{ collapsed, setCollapsed}}
            style={{ marginBottom: '24px', marginTop: '16px' }} children={undefined} />
    

          <Menu menuItemStyles={menuItemStyles} >
            {userInfo["membertype"] > 2 ?
               menus.map((menu, index) =>  menuBuilder(menu, index)) 
              :
                menus2.map((menu, index) =>  menuBuilder(menu, index)) 
            }
          </Menu>

            
         
          {/* <div>
          {Object.entries(userInfo).map(([key, value]) => (
            <div key={key}>
              {key}: {value?.toString() ?? "값없음"}
            </div>
          ))}
        </div>
 */}
        <div className="pb-3" style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <div className="pb-3">{ userInfo["user_id"] }</div>
          <Button variant="primary" onClick={signoutHandler}>
          Signout
          </Button>
          
        </div>
        
        </Sidebar>

      </div>
    );

      
    function menuBuilder(parent, parentIndex) {
        //var isActive = pathName === parent.path ? true : false;
        if  (parent.children == null) {
          if (parent.path != null) {
            return <MenuItem key={parentIndex} component={<Link to={parent.path} />}> {parent.name} </MenuItem>;                
          }
          return subheader(parent.name, parentIndex);
        }

        return <SubMenu key={parentIndex} label={parent.name}
                suffix={
                  <Badge variant="danger" shape="circle" className='d-none'>
                    
                  </Badge>
                }>
            {parent.children.map((menu, index) =>{
              if (menu.path != null)
                return (<MenuItem key={index} component={<Link to={menu.path} />}> {menu.name} </MenuItem>);
              return subheader(menu.name, index);
            }
            )}
        </SubMenu>;
        
        function subheader(name, index) {
          return (<div key={index} style={{ padding: '20px 24px' }}>
          <Typography          
            variant="body2"
            fontWeight={200}
            style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
          >
            {name}
          </Typography>
        </div>);

        }
    }

  }

export default SideMenuBuilder;
