import axios from 'axios';
import Cookies from 'js-cookie';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER
});

class Api {

  static post = (command, params = {}) => {
    // 쿠키에서 특정 값을 읽어옵니다.
    const utk = Cookies.get('utk');
    // 헤더 객체 초기화
    let headers = {};

    // 'utk' 값이 존재할 경우에만 헤더에 추가합니다.
    if (utk) {
      headers['utk'] = utk;
    }

    const data = { ...params, command: command };
    return apiClient.post('/api', data, { headers });
  }


  static postFileUpload = async (command, params={}, selectedFile) => {
    if (selectedFile) {
    
      const formData = new FormData();
      formData.append('command', command);
      Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
      });
      formData.append('file', selectedFile);

      // 쿠키에서 특정 값을 읽어옵니다.
      const utk = Cookies.get('utk');
      // 헤더 객체 초기화
      let headers = {'Content-Type': 'multipart/form-data'};

      // 'utk' 값이 존재할 경우에만 헤더에 추가합니다.
      if (utk) {
        headers['utk'] = utk;
      }
      
      return apiClient.post('/api', formData, { headers:headers });
    }
  };

  
  static signin = (id,pwd) => Api.post('signin', {item:{user_id:id, pwd:pwd}});
  static signup = (id,pwd) => Api.post('signup', {item:{user_id:id, pwd:pwd}});
  static signout = () => Api.post('signout');
  static userinfoGet = () => Api.post('userinfoGet');

  static usersScan = (lastID) => Api.post('usersScan', {lastID:lastID});
  static usersSearchScan = (term, lastID) => Api.post('usersSearchScan', {term:term, lastID:lastID});
  
  static addUser = (userId, pwd, name, phone, membertype, grade) => Api.post('addUser', {item:{user_id:userId, pwd:pwd, name:name, phone:phone, membertype:membertype, 직급:grade}});
  static userPut = (uidx, name, phone, membertype, grade) => Api.post('userPut', {item:{uidx:uidx, name:name, phone:phone, membertype:membertype, 직급:grade}});
  static userPwdPut = (uidx, pwd) => Api.post('userPut', {item:{uidx:uidx, pwd:pwd}});
  
  
  static taskCreate = (information, stepTask) => Api.post('taskCreate', {information:information, stepTask:stepTask});
  static taskUpdate = (information, stepTask) => Api.post('taskUpdate', {information:information, stepTask:stepTask});
  static taskDelete = (ID)=> Api.post('taskDelete', {ID:ID});
  

  static taskScan = (period, type, finishOnly) => Api.post('taskScan', {period:period, type:type, finishOnly:finishOnly});
  static taskGet = (ID) => Api.post('taskGet', {ID:ID});
  static taskMyScan = (period) => Api.post('taskMyScan', {period:period});
  static taskStatusChange = (ID, step_code, isBegan) => Api.post('taskStatusChange', {ID:ID, step_code:step_code,isBegan:isBegan});  
  static taskBGPut = (ID, step_code, bg) => Api.post('taskBGPut', {ID:ID, step_code:step_code,bg:bg});  
  
  static settingValuesScan = () => Api.post('settingValuesScan');
  static settingValuesPut = (k,v) => Api.post('settingValuesPut', {key:k, value:v});
  static settingValuesDel = (k) => Api.post('settingValuesDel', {key:k});
  

}

export default Api;

