
import './App.css';
import { Outlet } from 'react-router-dom';
// @ts-ignore
import SideMenuBuilder from "./side_menu/SideMenuBuilder.tsx";
import {Loading} from './components/Loading';
import React, { useState, useEffect } from 'react';
import Login from './pages/Login.jsx'
import Cookies from 'js-cookie';

import { SettingsScan } from './data/Setting.jsx';

import { UserinfoGet, SignOut } from './data/UserInfo.jsx';
import { useRecoilValue } from 'recoil';
import { userInfoState } from './data/UserInfo.jsx';
import { useNavigate } from 'react-router-dom';

function App() {
  const userInfo = useRecoilValue(userInfoState); 
  const [isLogin, setIsLogin] = useState(0);//0:모름 1:실패 2:성공

  //const fetchSettings = SettingsScan();
  const fetchUserInfo = UserinfoGet();
  const fetchSignout = SignOut();

  useEffect(() => {
    // 앱이 로드될 때 실행
    const utkCookie = Cookies.get('utk');
    if (utkCookie) {
      //서버에서 유저데이터를 가져온다
      fetchUserInfo().then((ret)=>{
        setIsLogin(ret?2:1);
      });
    } else {
      setIsLogin(1);
    }
    
    //fetchSettings();  
    
  }, []); 

  let navigate = useNavigate();
  const loginHandler = () => {
      navigate('/'); 
      setIsLogin(2);
  };

  const logoutHandler = async () => {
    if (await fetchSignout())
      setIsLogin(1);    
  };

  
  
 
  // 로그인 여부에 따라 렌더링할 페이지가 달라짐
  const renderDivBasedOnValue = ()=> {
    switch (isLogin) {
      case 1:
        return <Login loginHandler={loginHandler}/>
      case 2:
        return (       
        <div style={{ display: 'flex',  flexDirection: 'row', height:"100vh", backgroundColor:"white", width:"100%", overflowX:"hidden"}}>
        { (userInfo["membertype"] !== 1)?
          <SideMenuBuilder signoutHandler={logoutHandler}/>:
          <div><button className='btn btn-outline-secondary btn-sm' onClick={logoutHandler}>Signout</button></div>
        }
        <Outlet />        
        <Loading/>
      </div>    
      ) 
      //로딩중이거나, 예외상항
      default:
        return <div></div>;
    }
  }

  return (
    <div className='App'>      
     {renderDivBasedOnValue()}
    </div>
  );
}


export default App;



