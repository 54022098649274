import React, { Component, useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Dropdown from 'react-bootstrap/Dropdown';
import Api from "../../api/Api";

import { useSetRecoilState } from "recoil";
import { isLoadingState } from "../../components/Loading";
import md5 from 'crypto-js/md5';
import {membertypeTitle} from "../../utils.js"

export const CreateUserModal = ({ show, handleClose }) =>  {
  const setLoading = useSetRecoilState(isLoadingState);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [grade, setGrade] = useState('');
  const [membertype, setSelectedValue] = useState(1);
  
  const handleSelect = (e) => {
    setSelectedValue(e);
  };

  const dropdown = ()=>{
    return (<div><Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {membertypeTitle(membertype)}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1">권한없음</Dropdown.Item>
        <Dropdown.Item eventKey="2">작업자</Dropdown.Item>
        <Dropdown.Item eventKey="4">관리자</Dropdown.Item>
        <Dropdown.Item eventKey="8">마스터</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>);
  }

  const handleSubmit = async (event)=>{
    setLoading(true);

    const response = await Api.addUser(userId.trim(), md5(password).toString(), name, phone, membertype, grade);
    setLoading(false);
    if (!response.data.ret) {
      alert(response.data.message);
      return false;
    }
    alert("추가 되었습니다.");
  };

  return (
    
      <Modal show={show} onHide={handleClose} size="sm">
          <Modal.Header closeButton>
              <Modal.Title>계정 생성</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left', width:'100%' }}>ID</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="ID" 
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>Password</Form.Label>
                <Form.Control 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>이름</Form.Label>
                <Form.Control 
                    placeholder="이름을 입력해주세요" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>전화번호</Form.Label>
                <Form.Control 
                    placeholder="전화번호를 입력해주세요" 
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>직급</Form.Label>
                <Form.Control 
                    placeholder="직급을 입력해주세요" 
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>권한등급</Form.Label>
                {dropdown()}      
              </Form.Group>


              <Button variant="primary" type="submit" className="w-100">계정 생성</Button>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
      </Modal>
  );
}



export default CreateUserModal;
