import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import styled from '@emotion/styled';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../api/Api";
import { InputGroup, FormControl } from 'react-bootstrap';
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";
import { UserItem } from "../../types/UserItem";
import UserModal from "./UserModal.tsx";
import { CreateUserModal } from "./CreateUserModal.tsx";
import {membertypeTitle} from "../../utils.js"

import UserSearch from "./UserSearch.tsx";
import UsersTable from "./UsersTable.tsx";

export const Users = () => {
  const [data, setData] = React.useState<UserItem[]>([]);
  const [searchedData, setSearchedData] = React.useState<UserItem[]>([]);
  const [searchTerm, setSearchTerm] = React.useState(""); // _searchTerm를 상태로 관리
  const [showCreateUserModal, setShowCreatedUserModal] = useState(false);

  const handleSearch = (_searchTerm) => {    
    // 앞뒤 공백을 제거
    const trimmedTerm = _searchTerm.trim();
    if (!trimmedTerm) {
      setSearchedData([]);
      return;
    }
    setSearchTerm(trimmedTerm);
    Api.usersSearchScan(trimmedTerm)
      .then((response) => {
        if (!response.data.ret) {
          return alert(response.data.message);
        }
        setSearchedData(response.data.items);
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  };

  const filterUniqueUsers = (existingUsers, newUsers) => {
    return newUsers.filter(newUser => 
      !existingUsers.some(existingUser => existingUser.uidx === newUser.uidx)
    );
  };

  const loadMore = () =>{
    // 마지막 UserItem의 uidx를 추출
    const lastUserId = data[data.length - 1].uidx;
    Api.usersScan(lastUserId).then((response) => {
      if (!response.data.ret) {
        return alert(response.data.message);
      }
      const newUsers: UserItem[] = response.data.items as UserItem[];
      setData(prevData => [...prevData, ...filterUniqueUsers(prevData, newUsers)]);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  
  
  const loadMoreSearch = () =>{
     // 마지막 UserItem의 uidx를 추출
     const lastUserId = searchedData[searchedData.length - 1].uidx;
     Api.usersSearchScan(searchTerm, lastUserId).then((response) => {
      const newUsers: UserItem[] = response.data.items as UserItem[];
      setSearchedData(prevData => [...prevData, ...filterUniqueUsers(prevData, newUsers)]);
    })
     .catch((error) => {
       console.error("Error fetching data:", error);
     });
  };

  React.useEffect(() => {
    Api.usersScan().then((response) => {
        const userItems: UserItem[] = response.data.items as UserItem[];
        setData(userItems);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Page>
      <PageHeader>
        <div className="d-flex justify-content-between"> 
        <div></div>
        회원 리스트
        <div>
          <button className="btn btn-sm btn-outline-secondary" onClick={()=>setShowCreatedUserModal(true)}>계정생성</button>
        </div>
        </div>
      </PageHeader>
      <PageMain>
        <UserSearch onSearch={handleSearch}/>
        {searchedData.length > 0 && <UsersTable rows={searchedData} loadMore={loadMoreSearch} />}
        <div className="mt-3">
          <UsersTable rows={data} loadMore ={loadMore}/>
        </div>
      </PageMain>
      <CreateUserModal show={showCreateUserModal} handleClose={()=>setShowCreatedUserModal(false)}/>
    </Page>
  );
};




export default Users;
