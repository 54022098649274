import React from "react";
import Form from 'react-bootstrap/Form';

import { taskTitle } from '../../../utils.js';

import InputField from "./InputField.jsx";

const TaskManufactureTable = ({ stepTask, setStepTask, codes, handleOpenModal }) => {
  return (
    <table className="table table-data mt-3 table-bordered ">
      <thead>
        <tr>
          <th style={{ width: "5rem" }}>작업</th>
          {codes.map((code, i) => (
            <th key={i} style={{ textAlign: 'center' }}>
              {code === "02" ? "** 하단참조 **" : taskTitle(code)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th style={{ textAlign: 'right' }}>시작일</th>
          {codes.map((code, i) => (
            <td key={i}>
              {code === "02" ? (
                <div> - </div>
              ) : (
                <InputField
                  stepTask={stepTask}
                  setStepTask={setStepTask}
                  code={code}
                  field={"시작일"}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <th style={{ textAlign: 'right' }}>종료일</th>
          {codes.map((code, i) => (
            <td key={i}>
              {code === "02" ? (
                <div> - </div>
              ) : (
                <InputField
                  stepTask={stepTask}
                  setStepTask={setStepTask}
                  code={code}
                  field={"종료일"}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <th style={{ textAlign: 'right' }}>비고</th>
          {codes.map((code, i) => (
            <td key={i}>
              {code === "02" ? (
                <div> - </div>
              ) : (
                <InputField
                  stepTask={stepTask}
                  setStepTask={setStepTask}
                  code={code}
                  field={"비고"}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <th style={{ textAlign: 'right' }}>작업자</th>
          {codes.map((code, i) => (
            <td key={i}>
              {code === "02" ? (
                <div> - </div>
              ) : (
                <Form.Control
                  type="text"
                  value={stepTask[code]?.["worker"] ?? ''}
                  onClick={() => handleOpenModal(code)}
                  readOnly
                />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};


  
export default TaskManufactureTable;