
import {menus,menus2} from '../settings/Menus'

//Element페이지가 있는 메뉴만 보여준다 
export const removeLastNodesWithoutElement = (menus3) => {
    if( menus3 === undefined)
        menus3 = [...menus];
        
    var r = menus3.reduce((acc, menu) => {
        if (menu.children) {
            menu.children = removeLastNodesWithoutElement(menu.children);
            if (menu.children.length > 0) {
                acc.push(menu);
            }
        } else if (menu.element || !menu.path) {
            
            acc.push(menu);
        }
        return acc;
    }, []);
    return r;
};


export { removeLastNodesWithoutElement as SideMenuFilter };


export const removeLastNodesWithoutElement2 = (menus3) => {
    if( menus3 === undefined)
        menus3 = [...menus2];
        
    var r = menus3.reduce((acc, menu) => {
        if (menu.children) {
            menu.children = removeLastNodesWithoutElement(menu.children);
            if (menu.children.length > 0) {
                acc.push(menu);
            }
        } else if (menu.element || !menu.path) {
            
            acc.push(menu);
        }
        return acc;
    }, []);
    return r;
};

