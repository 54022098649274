export function taskTitle(code) {
  //const title = {"01":"접수","02":"설계","03":"프로그램","04":"", "05":"제조2","06":"제조3" , "07":"출하", "qc":"QC", "as":"AS"};
  //return title[code];
  return code.toUpperCase();
}

// export function completedLabel(completed) {
//   return completed === 0 ? (<span className='text-secondary'>N</span>) : (<span className='fw-bold'>Y</span>);
// }

export function membertypeTitle(v) {
  var x = parseInt(v,10)
  if ((x & 0b1000) !== 0) { // 2진수 1000 (8 in decimal)이 켜져 있으면
    return '마스터';
  } else if ((x & 0b0100) !== 0) { // 2진수 0100 (4 in decimal)이 켜져 있으면
    return '관리자';
  } else if ((x & 0b0010) !== 0) { // 2진수 0010 (2 in decimal)이 켜져 있으면
    return '작업자';
  } else {
    return '권한없음'; // 어떤 조건도 충족되지 않으면
  }
}



export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth()는 0에서 시작
  const day = date.getDate().toString().padStart(2, '0');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}