import Users from '../pages/users/Users.tsx'

import ValueSetting from '../pages/settings/ValueSetting.jsx'
import Home from '../pages/dashboard/Home.jsx'
import CreateTask  from '../pages/tasks/createTask/CreateTask.jsx';
import FindMyTask  from '../pages/tasks/FindMyTask.jsx';

export const menus = [
    { name :"작업 관리", children :[
        { name: "작업 대쉬보드", path: "/tasks/dashboard", element:<Home/> },
        { name: "작업 생성", path: "/tasks/create", element:<CreateTask/> },
        { name: "내 담당작업", path: "/tasks/findMyTask", element:<FindMyTask/> },
        
    ]},
    { name :"유저리스트", children :[
        { name: "전체유저", path: "/users/all", element:<Users/> },
        { name: "관리자", path: "/users/admin" }
    ]},
    { name :"설정", children :[
        { name: "세팅값 설정", path: "/settings/valueSetting", element :<ValueSetting/> },
    ]},
];

export const menus2 = [
    { name :"general",  },
    { name :"작업 관리", children :[
        { name: "내 담당작업", path: "/tasks/findMyTask", element:<FindMyTask/> },
        
    ]},
];

export default menus;
