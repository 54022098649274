import React, { useEffect, useState } from "react";
import { PageHeader, Page, PageMain } from "../../../components/Page.jsx";

import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../../api/Api.js";

import { useSetRecoilState } from "recoil";
import { isLoadingState } from "../../../components/Loading.jsx";

import UserFinderModal from '../UserFinderModal.tsx'
import TaskNumberInput from "./TaskNumberInput.jsx";


import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string'; // queryString 라이브러리를 사용
import TaskManufactureTable from './TaskManufactureTable.jsx'
import TaskDesignTable from './TaskDesignTable.jsx'



const TaskCreateInputArea = ({modifyingId, item}) =>{
  let navigate = useNavigate();
  const setLoading = useSetRecoilState(isLoadingState);


  //task informations 
  const [taskNumber, setTaskNumber] = useState('');
  const [projectName, setProjectName] = useState('');
  const [customer, setCustomer] = useState('');
  const [sales, setSales] = useState(0);

  const [dC, setDC] = useState('');
  const [dH, setDH] = useState('');
  const [dN, setDN] = useState('');
  const [dE, setDE] = useState('');

  //tasks progress  
  const [stepTask, setStepTask] = useState({});

  //select worker 
  const [selectedCode, setSelectedCode] = useState(null);
  const [showModal, setshowModal] = useState(false);

  // main step 단계 - 01 접수는 빼라고 함
  const codes = ["pg", "제조1", "제조2", "제조3", "출하", "qc", "as"];
  const [step2codes, setStep2codes] = useState(["0201"]);

  
  useEffect(()=>{
    if (!item || item["수주번호"] === undefined){
      return;
    }
    fetchItem();
  },[item]);

  const convertToMap = (details) => {
    return details.reduce((map, detail) => {
      const { step_code, name, ...rest } = detail;
      map[step_code] = {worker:name, ...rest};
      return map;
    }, {});
  };

  // 서버에서 아이템을 로드합니다. 
  const fetchItem = () =>{
    setTaskNumber(item["수주번호"] ?? "");
    setProjectName(item["프로젝트명"] ?? "");
    setCustomer(item["거래처"] ?? "");
    setSales(item["예상매출액"] ?? 0);

    setDC(item["도장색상"] ?? "");
    setDH(item["도장두께"] ?? "");
    setDN(item["도장내진"] ?? "");
    setDE(item["도장기타"] ?? "");

    
    const detail_map = convertToMap(item["details"]);
    setStepTask(detail_map);

    const design = item["details"].filter(detail => detail["step_code"] != null && detail["step_code"].length === 4)
    const design_key = design.map(detail => detail["step_code"]);
    setStep2codes(design_key);
  }

  const informationBuilder = ()=>{
    return {
      ID : modifyingId,
      수주번호 : taskNumber.trim(),
      프로젝트명 : projectName.trim(),
      거래처 : customer.trim(),
      예상매출액 : sales,
      도장색상: dC.trim(),
      도장두께: dH.trim(),
      도장내진: dN.trim(),
      도장기타: dE.trim()
    };
  }

  const handleSubmit = async ()=>{
    
    if (taskNumber.trim().length === 0)return alert("수주번호를 넣어주세요")
    if(!window.confirm("작업을 생성합니다"))return;

    setLoading(true);
    var ret = (await Api.taskCreate(informationBuilder(), stepTask)).data;
    setLoading(false);

    if (!ret["ret"]) {
      return alert(ret["message"]);
    }
    if (window.confirm("작업이 생성되었습니다\n대쉬 보드로 이동할까요?")) {      
      navigate('/tasks/dashboard'); 
    }
  }

  const handleSubmitModify = async ()=>{
    
    if (taskNumber.trim().length === 0)return alert("수주번호를 넣어주세요")
    setLoading(true);
    var ret = (await Api.taskUpdate(informationBuilder(), stepTask)).data;
    setLoading(false);

    if (!ret["ret"]) {
      return alert(ret["message"]);
    }
    alert("작업이 저장되었습니다");
  }

  const handleDelete = async()=>{
    if (modifyingId === undefined || !window.confirm("작업을 삭제하시겠습니까?")) {  return ; }

    setLoading(true);
    var ret = (await Api.taskDelete(modifyingId)).data;
    setLoading(false);

    if (!ret["ret"]) {
      return alert(ret["message"]);
    }

    navigate('/tasks/dashboard'); 

  }

  const handleOpenModal = (code) => {
    setshowModal(true);
    setSelectedCode(code)
  };

  const handleCloseModal = () => {
    setshowModal(false);
  };

  const handleSelectWorker = (row) => {
    setStepTask(prevStepTask => {
      // 새로운 객체를 생성하여 이전 상태와 병합
      return {
        ...prevStepTask, 
        [selectedCode]: {
          ...prevStepTask[selectedCode],
          worker: row["name"],
          worker_uidx: row["uidx"],
        }
      };
    });
    setshowModal(false);
  };



  return (
    
    <div className="d-flex align-item-center justify-content-center">
    <div className="col-xl-12 col-lg-12 col-md-12">
      <div>
        <table className="table table-striped" >
          <tbody>
            <tr className="small" >
              <th>수주번호</th>
              <th>프로젝트명</th>
            </tr>
            <tr>
              <td><TaskNumberInput taskNumber={taskNumber} setTaskNumber={setTaskNumber}/></td>
              <td><Form.Control value={projectName}  placeholder="프로젝트명" onChange={(e)=>{setProjectName(e.target.value);}}  /></td>
            </tr>
            <tr className="small" backgroundColor="#f00">
              <th>거래처</th>
              <th>예상매출액</th>
            </tr>
            <tr>
              <td><Form.Control value={customer}  placeholder="거래처" onChange={(e)=>{setCustomer(e.target.value);}}  /></td>
              <td>
                <Form.Control value={sales} style={{  textAlign:'right'}} placeholder="예상매출액" type="number"  onChange={(e)=>{setSales(e.target.value);}}/>
              </td>
            </tr>
            <tr className="small" backgroundColor="#f00">
              <th>도장정보 - 색상</th>
              <th>도장정보 - 두께</th>
            </tr>
            <tr>
              <td>
                <Form.Control value={dC}  placeholder="색상" onChange={(e)=>{setDC(e.target.value);}}  /></td>
              <td>
                <Form.Control value={dH} placeholder="두께"  onChange={(e)=>{setDH(e.target.value);}}/>
              </td>
            </tr>
            <tr className="small" backgroundColor="#f00">
              <th>내진여부</th>
              <th>기타</th>
            </tr>
            <tr>
              <td>
                <Form.Control value={dN}  placeholder="내진여부" onChange={(e)=>{setDN(e.target.value);}}  />
                </td>
              <td>
                <Form.Control value={dE} placeholder="기타" onChange={(e)=>{setDE(e.target.value);}}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <div>        
        <div className="mt-5">
          <div className="mt-5" style={{height:10, width:"100%", backgroundColor:"#e0e0e0"}}></div>
            <label className="fw-bold mt-3">설계</label>
            <TaskDesignTable 
              stepTask={stepTask} 
              setStepTask={setStepTask} 
              step2codes={step2codes} 
              setStep2codes={setStep2codes} 
              handleOpenModal={handleOpenModal}/>
            <div className="mt-3" style={{height:10, width:"100%", backgroundColor:"#e0e0e0"}}></div>
        </div>
          
        <div >
          <TaskManufactureTable stepTask={stepTask} setStepTask={setStepTask} codes={codes} handleOpenModal={handleOpenModal}/>        
        </div>
      </div>
      
      <div className="mt-3">
        <div className="bg-secondary mb-3" style={{height:1, width:"100%"}}></div>
        {modifyingId!==undefined?<div>
          <Button variant="success" className="w-100" onClick={handleSubmitModify}>작업 저장</Button>
          <Button variant="danger" className="w-100 mt-3" onClick={handleDelete}>작업 삭제</Button>
          </div>
          :
          <Button variant="success" className="w-100" onClick={handleSubmit}>작업 생성</Button>
        }
      </div>
      

      <UserFinderModal
        show={showModal}
        onHide={handleCloseModal}
        onSave={handleSelectWorker}
      />
      </div>
    </div>
  );
}



const CreateTask = () =>{

  const [ID, setID] = useState();
  const [item, setItem] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.ID) {
      Api.taskGet(params.ID);
      setID(params.ID);
      load(params.ID);
    }
  },[location]);

  const load = async (ID) =>{
    var ret = (await Api.taskGet(ID)).data;
    if (!ret["ret"]){
      alert(ret["message"]);
      navigate(-1); 
      return;
    }
    setItem(ret.item);
  }

  return (
    <Page>
      <PageHeader>{ID!==undefined?"작업 내용 수정":"작업 생성"}</PageHeader>
      <PageMain>
        <TaskCreateInputArea modifyingId={ID} item={item}/>
      </PageMain>
    </Page>
  );
}


export default CreateTask;