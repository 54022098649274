import React, { useState } from 'react';
import { useSetRecoilState } from "recoil";
import styled from '@emotion/styled';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import {isLoadingState} from '../components/Loading';

import Api from "../api/Api.js";
import { Signin } from '../data/UserInfo.jsx';
import md5 from 'crypto-js/md5';

const StyledCard = styled(Card)`
  width: 300px;
  margin: auto;
  margin-top: 100px;  
  border-radius: 5px;
  border-color: dark;
  background-color: #f5f5f5;
`;

function Login({ loginHandler }) {
  const setLoading = useSetRecoilState(isLoadingState);

  const fetchSignin = Signin();

  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
      event.preventDefault();
      var ret = await fetchSignin(userId, password);
      if (ret) {
        loginHandler();
      } 
  };

  const _handleSignup = async (event)=>{
    setLoading(true);
    const response = await Api.signup(userId, md5(password).toString());
    setLoading(false);
    if (!response.data.ret) {
      alert(response.data.message);
      return false;
    }
    alert("가입성공! 관리자 처리 후 로그인해주세요");
  };

  return (
    <StyledCard>
      <Card.Header style={{ fontWeight: 'bold'}}>SPM ERP 어드민 로그인</Card.Header>
      <Card.Body>
        <Container>
          <Row className="justify-content-md-start">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left', width:'100%' }}>ID</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Enter ID" 
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={{ fontWeight: 'bold', textAlign: 'left' , width:'100%'}}>Password</Form.Label>
 
                <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                </Form.Group>
                <div className='d-flex justify-content-around'>
                  <Button variant="primary"  onClick={_handleSignup} className='d-none'>가입</Button>
                  <Button variant="primary" type="submit" >로그인</Button>
                </div>
                </Form>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </StyledCard>
  );
}

export default Login;

