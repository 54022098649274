import React from "react";
import Form from 'react-bootstrap/Form';

  
const InputField = ({stepTask, setStepTask, code, field})=>{
  return (<Form.Control value={stepTask[code]?.[field] ?? ''} placeholder={field} onChange={(e) => {
    setStepTask(prevStepTask => {
      // 새로운 객체를 생성하여 이전 상태와 병합
      return {
        ...prevStepTask, 
        [code]: {
          ...prevStepTask[code],
        [field]: e.target.value // 사용자가 입력한 값으로 '시작일'을 업데이트
        }
      };
    });}}/>);
}

export default InputField;