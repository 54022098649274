import React, { Component, useEffect, useState } from "react";
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";

import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../api/Api.js";

import { useSetRecoilState } from "recoil";
import { isLoadingState } from "../../components/Loading.jsx";
import {Tooltip} from 'react-tooltip';
import { taskTitle } from '../../utils.js';

import PeriodButtonGroup from "../dashboard/PeriodButtonGroup.jsx"
import CommentsDesc from "../dashboard/CommentsDesc.jsx"
import DataTable from "../dashboard/DataTable.jsx"



const FindMyTask = () =>{
  const [tasks, setTasks] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [periodMode, setPeriodMode] = useState(0);
  const [type, setType] = useState('');
  
  useEffect(() => { reload(); }, [type, periodMode]); 


    
 
  const reload = async ()=>{
    var ret = (await Api.taskMyScan(periodMode)).data;
    if (!ret["ret"]){
      return alert(ret["message"]);
    }     
    setTasks(ret.items);    
  }

  const handleYNClick = async (cell) =>{
    if (cell["completed_at"] !== null){
      if (!window.confirm('시간을 reset 하시겠습니까????'))return;
      var ret = (await Api.taskStatusChange(cell["task_ID"], cell["step_code"], -1)).data;
      if (!ret["ret"]) {
        return alert(ret["message"]);
      }
      alert("시간을 reset 했습니다.");
    }

    else if (cell["began_at"] === null){
      if (!window.confirm('작업을 시작 하시겠습니까??'))return;
      var ret = (await Api.taskStatusChange(cell["task_ID"], cell["step_code"], 1)).data;
      if (!ret["ret"]) {
        return alert(ret["message"]);
      }
      alert("작업을 시작했습니다.");
      // var bg = window.prompt('작업을 시작했습니다.\n비고란을 입력해주세요');

      // if (bg != null){
      //   var ret2 = (await Api.taskBGPut(cell["task_ID"], cell["step_code"], bg)).data;
      //   if (!ret2["ret"]) {
      //     return alert(ret2["message"]);
      //   }
      // }


    } else {
      if (!window.confirm('작업완료를 하시겠습니까?'))return;
      var ret = (await Api.taskStatusChange(cell["task_ID"], cell["step_code"], 0)).data;
      if (!ret["ret"]) {
        return alert(ret["message"]);
      }  
    }

    reload();
  }

  return (
    <Page>
      <PageHeader>내 작업 현황</PageHeader>
      <PageMain style={{overflowX: "auto"}}>
        <div className='d-flex justify-content-start'>
          <PeriodButtonGroup handleClick={(pm)=>{
              setPeriodMode(pm);
            }}
            periodMode={periodMode}
            handleExpand={()=>setExpanded(!expanded)} expanded={expanded}/>
          <CommentsDesc/>
        </div>
         <div className="mt-3" style={{width:expanded?"430rem":"100%"}}>
            {tasks.length === 0 ? (
              <span>Task가 없습니다.</span>
            ) : (
              <DataTable data2={tasks} expanded={expanded} handleYNClick={handleYNClick}/>
            )}
          
        </div>
    </PageMain>
    </Page>
  );


}


export default FindMyTask;