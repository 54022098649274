import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FaExpand, FaCompress } from 'react-icons/fa';

const CommentsDesc = () => {
  const [expanded, setExpanded] = React.useState(true);
  const handleExpand = () => setExpanded(!expanded);

  return (<>
    <div className='ms-3'>
    <ButtonGroup size="sm">
      <Button variant="success" onClick={handleExpand}>{expanded?<FaCompress />:<FaExpand />}</Button>
      {expanded && <span  className='bg-success text-white px-3  py-1' style={{borderTopRightRadius:"3px", borderBottomRightRadius:"3px"}}>
      비고 : 1. 회사협조지연, 2.작업시간지연, 3. 설계수정, 4. 프로그램수정, 5. 이전공정지연, 6. 재제작지연, 7 재도장지연, 8 기타																
      </span>}
    </ButtonGroup>
    </div>
    </>
  );
}

export default CommentsDesc