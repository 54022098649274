import styled from '@emotion/styled';
import React from 'react';


const StyledPage = styled.div`
  margin: 0;
  background-color:#eee;
  height:100vh;
  width:100vw;
  box-sizing: border-box;
`;

export const Page = ({children }) => {
  return (
    <StyledPage>
      {children}
    </StyledPage>
  );
};


const StyledPageHeader= styled.div`
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.5rem; 
  line-height: 2rem;
  background-color:#fff;
  padding:1rem;
  width:100%;
  box-sizing: border-box;
`;

export const PageHeader = ({children }) => {
  return (
    <StyledPageHeader>
      {children}
    </StyledPageHeader>
  );
};

const StyledPageMain = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto; /* 스크롤 활성화 */
`;



export const PageMain = ({children }) => {
  return (
    <StyledPageMain>
      {children}
    </StyledPageMain>
  );
};
