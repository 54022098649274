import React, { useState, useEffect } from 'react';
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";
import Api from "../../api/Api.js";
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../data/UserInfo.jsx';

import PeriodButtonGroup from "./PeriodButtonGroup.jsx"
import TypeButtonGroup from "./TypeButtonGroup.jsx"
import FinishButtonGroup from "./FinishButtonGroup.jsx"
import CommentsDesc from "./CommentsDesc.jsx"

import DataTable from "./DataTable.jsx"
import * as XLSX from 'xlsx';

const Home = () =>{
  const userInfo = useRecoilValue(userInfoState); 
  const [tasks, setTasks] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [periodMode, setPeriodMode] = useState(0);
  const [type, setType] = useState('');
  const [finishOnly, setFinishOnly] = useState(-1);
  
  useEffect(() => { reload(); }, [type, periodMode, finishOnly]); 
 
  const reload = async ()=>{
    var ret = (await Api.taskScan(periodMode, type, finishOnly)).data;
    if (!ret["ret"]){
      return alert(ret["message"]);
    }     
    setTasks(Array.isArray(ret.items) ? ret.items : []);    
  }

  const DashBoardForNoQualified = () =>{
    return (
      <div className="d-flex align-item-center justify-content-center">
        <div>
          <PeriodButtonGroup handleClick={reload} handleExpand={()=>{}} expanded={false}/>
          <TypeButtonGroup handleClick={reload}/>
          <div className="mt-3" style={{width:"100%"}}>
            <DataTable data2={tasks} expanded={false} />
          </div>
        </div>        
      </div>
    );
  }
  

  const exportTableToExcel = (tableId, fileName) => {
    const table = document.getElementById(tableId);
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  
  return (
    <Page>
      <PageHeader>
        <div className="d-flex justify-content-between">
          <button className='btn btn-sm btn-outline-secondary' onClick={() => exportTableToExcel('table-to-xlsx', '작업현황-')}>
            Export to Excel
          </button>
          <span>작업 현황 대쉬보드</span>
          <div></div>
        </div>
      </PageHeader>
      <PageMain style={{overflowX: "auto"}}>
        {userInfo["membertype"] > 1 ? <>
          <div className='d-flex justify-content-start'>
            <PeriodButtonGroup handleClick={(pm)=>{
              setPeriodMode(pm);
            }} handleExpand={()=>setExpanded(!expanded)} expanded={expanded} periodMode={periodMode}/>
            <div style={{width:"10px"}}></div>
            <TypeButtonGroup handleClick={ (t)=>{
              setType(t);
            }} type={type}/>
            <div style={{width:"10px"}}></div>
            <FinishButtonGroup handleClick={ (t)=>{
              setFinishOnly(t);
            }} finishOnly={finishOnly}/>
            <CommentsDesc/>
          </div>

          
          <div className="mt-3" style={{width:expanded?"530rem":"100%"}}>

            {tasks.length === 0 ? (
              <span>Task가 없습니다.</span>
            ) : (
              <DataTable data2={tasks} expanded={expanded} handleYNClick={()=>{}} />
            )}
          </div>          
          </>
        :
         <DashBoardForNoQualified/>
        }
      </PageMain>
    </Page>
  );

}


export default Home;

