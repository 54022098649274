import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import styled from '@emotion/styled';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../api/Api";
import { InputGroup, FormControl } from 'react-bootstrap';
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";
import { UserItem } from "../../types/UserItem";
import UserModal from "./UserModal.tsx";
import { CreateUserModal } from "./CreateUserModal.tsx";
import {membertypeTitle} from "../../utils.js"


const SearchDivBG = styled.div`
  background-color: pink;
  padding: 20px;
  border-radius: 5px; 
`;


export const UserSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    onSearch(searchTerm); // 부모 컴포넌트의 검색 함수 호출
  };

  const handleSearchClose = () => {
    setSearchTerm('');
    onSearch(''); 
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <SearchDivBG className="p-2">
      <InputGroup >
        <InputGroup.Text id="basic-addon1">유저찾기</InputGroup.Text>   
        <FormControl
          placeholder="유저 이름 입력, uidx검색:$uidx"
          value={searchTerm}
          onChange={(e) => {
            e.persist();
            setSearchTerm(e.target.value);
          }}
          onKeyDown={handleKeyDown} // 엔터 키 이벤트 핸들러 추가
        />
        <Button variant="outline-secondary bg-light"  onClick={handleSearch}>
          검색
        </Button>
        <Button variant="outline-secondary bg-light"  onClick={handleSearchClose}>
        &times;
        </Button>
      </InputGroup>
    </SearchDivBG>
  );
};

export default UserSearch;