import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import styled from '@emotion/styled';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Api from "../../api/Api.js";
import { InputGroup, FormControl } from 'react-bootstrap';
import { PageHeader, Page, PageMain } from "../../components/Page.jsx";
import { UserItem } from "../../types/UserItem.ts";
import UserModal from "./UserModal.tsx";
import { CreateUserModal } from "./CreateUserModal.tsx";
import {membertypeTitle} from "../../utils.js"
import UserSearch from "./UserSearch.tsx";

interface Column {
  id: string;
  checkbox?: boolean;
  label: string;
  minWidth?: string | number;
  align?: "right"|"center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "uidx",
    label: "uidx",
    minWidth: "5rem",
    format: (value: number) => value.toString(),
  },
  { id: "user_id", label: "ID", minWidth: "5rem" },
  { id: "name", label: "name", minWidth: "5rem" },
  { id: "phone", label: "phone", minWidth: 100 },
  { id: "직급", label: "직급", minWidth: 100 },
  { id: "membertype", label: "membertype", minWidth: 100 },
];

export const UsersTable = ({rows, loadMore}) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserItem, setSelectedUserItem] = useState(null);
  


  const handleUserModalClose = () => {
    setSelectedUserItem(null);
    setShowUserModal(false);
  }

  const handleShowUserModal = (row) =>{ 
    setSelectedUserItem(row);
    setShowUserModal(true);
  }
  
  
  if (rows == null)return <></>;

  return (
  
    <div className="table-container">
      <Table  className="mb-0 sticky-table" striped bordered hover size="lg" >
        <thead >
          <tr>
          {columns.map((column) => (
            <th
              key={column.id}          
              style={{textAlign:column.align, width: column.minWidth }}
            >
              {column.label}
            </th>
          ))}
          <th style={{ textAlign:"center", width: "5rem"}}>수정</th>
          </tr>
        </thead>
        <tbody>
        {rows.map((row) => {
            return (
              <tr tabIndex={-1} key={row.uidx}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <td key={column.id} align={column.align}>
                      {
                      column.id === "membertype"?
                        membertypeTitle(value):
                      column.checkbox === true ? (
                        <Form.Check aria-label="option 1" 
                        checked={value === 1}
                        disabled/>
                      
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : (
                        value
                      )}
                    </td>
                  );
                })}
                <td style={{ textAlign:"center"}}>
                  <Button variant="primary" size="sm" onClick={()=>handleShowUserModal(row)}>
                    프로필
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div>
        <button className="btn btn-sm btn-light w-100 btn-outline-dark" onClick={()=>loadMore()}>더보기</button>
      </div>
      {selectedUserItem && <UserModal show={showUserModal} handleClose={handleUserModalClose} item={selectedUserItem} />}
    </div>
  );
}

export default UsersTable;